import { AboutHeader, AboutParagraph, VerticalLine } from 'pages';

export const About = () => {
  return (
    <div
      id='about'
      className='z-[100] relative bg-light-color w-full min-h-full px-10 py-20 flex flex-col justify-center gap-[5rem] items-center'
    >
      <AboutHeader />
      <VerticalLine />

      <AboutParagraph
        title='Who am I?'
        text='I am a Software Engineer from Tbilisi, Georgia,
        with some experience in Web Development and programming. Furthermore, I
        can make pretty damn cool designs on my own.'
      />

      <VerticalLine />

      <AboutParagraph
        title='Work.'
        text='My Work experience comes from working as a full-time Full Stack
        Web Developer at Haru. I was working both
        Front-end (Next.js) and Back-end (Nest.js) development along with
        writing clean code and nice documentations. Also i have'
      />
    </div>
  );
};

export default About;
