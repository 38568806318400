export const EducationParagraphs = () => {
  return (
    <>
      <h1 className='text-dark-color text-2xl lg:text-3xl text-center font-Montserrat'>
        <span>
          <a
            className='text-crimson underline'
            href='https://www.facebook.com/bitcamp.ge'
            target='_blank'
            rel='noreferrer'
          >
            Bitcamp
          </a>
        </span>
      </h1>
      <p className='lg:text-lg leading-8 font-Montserrat'>
        A place where I first discovered the field of Web Development which gave
        me a solid foundation in programming. Furthermore, I have developed an
        elegant problem-solving and decent communication skills, which resulted
        in potential and dedication for consistent growth and improvement in
        this direction.
      </p>
      <p className='lg:text-lg leading-8 font-Montserrat'>
        Subjects that I have learned here include: Algorithms and data
        structures, Javascript, Typescript, React, Node.js, Express.js, MongoDB,
        relational databases (MSSQL).
      </p>
    </>
  );
};

export default EducationParagraphs;
