export const SocialContent = [
  {
    link: 'https://www.linkedin.com/in/olegbobokhidze/',
    img: 'linkedin',
  },
  {
    link: 'https://github.com/olegbobokhidze',
    img: 'github',
  },
];

export default SocialContent;
