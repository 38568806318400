export const SkillBottomPage = [
  {
    title: 'PHP',
    img: 'php',
    exp: '3 months',
  },
  {
    title: 'Laravel',
    img: 'laravel',
    exp: '3 months',
  },
  {
    title: 'Nest.js',
    img: 'nest',
    exp: '1 year',
  },
  {
    title: 'MySQL',
    img: 'sql',
    exp: '1 year',
  },
];

export default SkillBottomPage;
