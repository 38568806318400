export const Cards = [
  {
    title: 'Haru Software',
    img: 'harusoftware',
    description:
      'This is a project where I use Next.js and other packages like i18n for language change, swiper for carousel. and for styling tailwindcss.',
    buttons: [
      {
        title: 'Line Link',
        link: 'https://harusoftware.com/',
      },
    ],
    start: 'Jan, 5',
    end: 'Apr, 10',
  },
  {
    title: 'MN/ROOM',
    img: 'mnroom',
    description:
      'This is beauty salon MN/ROOM, where I use Next.js and other packages like i18n for language change, swiper for carousel and etc.',
    buttons: [
      {
        title: 'Live Link',
        link: 'https://www.mnroom.ge/en',
      },
    ],
    start: 'Jun, 3',
    end: 'Aug, 25',
  },
  {
    title: 'Detective Online Board Game',
    img: 'detective',
    description:
      'This is my own startup, we are creating detective board game that will be half online and half offline game, user will buy our board game and in that board game he/she will have perrmission to use our page for that current board game, for hints, for addition information and etc. for now it is in development, where i use Vue.js for frontend and nest.js for backend, also i use PostgreSQL for db and i use other packages.',
    buttons: [],
    start: 'Dec, 26',
    end: 'Jan, 6',
  },
  {
    title: 'ArtOa',
    img: 'artoa',
    description:
      'This is startup, where i am frontend developer, we are creating first AI/AR marketplace where user joins and with AI can generate some image and contact artist to draw that image. i am using React.js for frontend and other packages. It is in development.',
    buttons: [],
    start: 'Feb, 1',
    end: '-',
  },
];

export default Cards;
